import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  Image,
  BannerLinks,
  TextBox,
  Breadcrumbs,
  BlogSlider
} from '../../components'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import BannerImage from '../../assets/projektuebersicht.jpg'
import TakeOff from '../../assets/bannerlinks/takeoff.jpg'
import LinksRechts from '../../assets/linksrechts.png'
import NichtErreichbar from '../../assets/nichterreichbar.png'
import NaviUnten from '../../assets/naviunten.png'
import Abstaende from '../../assets/abstaende.png'
import Verschwinden from '../../assets/verschwinden.png'

import { generateBreadcrumbsLinks } from '../../utils/index'

const MobileNavigationGanzUnten = () => {
  const links = generateBreadcrumbsLinks('/blog/mobilenavigationganzunten', 'Mobile Navigation am unteren Bildschirmrand')
  return (
    <Layout>
      <Helmet
        title="Mobile Navigation am unteren Bildschirmrand"
        meta={[
          {
            name: 'description',
            content:
              'Bei der Benutzung eines Mobiltelefons hat Ihr Daumen eine begrenzte Bewegungsfreiheit. Die Lösung dieses Problems? Eine Navigation, die mit dem Daumer erreichbar ist'
          },
          {
            name: 'keywords',
            content:
              'mobile navigation holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Mobile Navigation, unterer Bildschirm'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/handynavigation-91fc394574095530870a49035a74a401.jpg'
          },
          {
            property: 'og:description',
            content:
              'Bei der Benutzung eines Mobiltelefons hat Ihr Daumen eine begrenzte Bewegungsfreiheit. Die Lösung dieses Problems? Eine Navigation, die mit dem Daumer erreichbar ist'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/handynavigation-91fc394574095530870a49035a74a401.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>Mobile Navigation</span>
        }
      />
      <Breadcrumbs
        backLinkAlias=' '
        links={links}
      />
      <TextBox
        title="Mobile Navigation am unteren Bildschirmrand"
        text={
          <span>
              <p><a href="https://www.dailymail.co.uk/sciencetech/article-2885213/Are-smartphones-changing-shape-BRAINS-Area-controls-thumbs-larger-people-use-touchscreens-daily.html" target="_blank"> Untersuchungen</a> haben ergeben, dass die meisten User ihre Telefone mit einer Hand benutzen. Wenn sie ihr Telefon halten, benutzen sie entweder ihren rechten oder linken Daumen, um mit dem Bildschirm zu interagieren. Der Daumen ist wie die Maus der Benutzer:innen, aber mit Einschränkungen.<br/><br/>
            </p>
            <h2>Daumen-Maus mit Einschränkungen</h2>
            <p>
              Bei der Benutzung eines Mobiltelefons hat Ihr Daumen eine begrenzte Bewegungsfreiheit. Es gibt bestimmte Bereiche des Bildschirms, die Sie nicht oder nur schwer erreichen können. Diese Bereiche variieren je nachdem, welche Hand Sie benutzen, um ihr Telefon zu halten und wie groß der Bildschirm des Telefons ist.
            </p>
          </span>
        }
      />
      <Image
        image={LinksRechts}
        title="Abbildung 1: Smartphone Bereiche, die mit dem Daumen erreichbar sind. Basierend auf durchschnittlicher Handgröße und Griffweite."
      />
      <TextBox
        title=""
        text={
          <span>
            Wenn eine Navigation in einem schwer zugänglichen Bereich platziert wurde, müssen Benutzer:innen ihr Mobiltelefon anders in die Hand nehmen oder ihre andere Hand benutzen, um mit diesem Bereich zu interagieren. Diese zusätzliche Arbeit kann das Navigieren schwerer machen und die Aufgabe der Benutzer:innen verlangsamen.
            <br />
            <br />
            Bei sehr großen Mobiltelefonen sind nicht nur obere Bereiche kaum mit dem Daumen zugänglich, sondern auch die Bereiche an der unteren gegenüberliegenden Bildschirmecke - siehe Abbildung 1 und 2.
          </span>
        }
      />
      <Image
        image={NichtErreichbar}
        title="Abbildung 2: Ein Beispiel, in dem das mobile Menü mit dem Daumen nicht erreichbar ist, außer man nimmt das Handy anders in die Hand."
      />
      <TextBox
        title=""
        text={
          <span>
            <h2>
              Mobiler <i>“sweet spot”</i>
            </h2>
            <p>
              Die Forschungsstudie hat den sogenannten “Sweet Spot” für Smartphone
              Benutzer:innen so beschrieben: <br />
              <br />
              <i>
                “Der ideale Ort, um das Menü zu platzieren, ist die untere Mitte
                des Telefons. Das ist der Punkt, der am einfachsten für linke und
                rechte Daumen auf kleinen und großen Bildschirmtelefonen zu
                erreichen ist.”
              </i>
            </p>
          </span>
        }
      />
      <Image
        image={NaviUnten}
        title="Abbildung 3: Smartphone Sweet Spot und Navigationsposition"
      />
      <TextBox
        title=""
        text={
          <span>
            <h2>Kritische Betrachtung und best practice</h2>
            <p>
              Es ist zum einen nicht wirklich neu, dass man in Smartphone Apps im unteren Bereich eine Navigation benützt.
            </p>
            <p>
              Die <b>untere Navigation am Desktop</b> ist eher ungewöhnlich und würden wir auch <b>nicht empfehlen</b>, da sie am Desktop leicht zu übersehen ist, da der Bildschirm einfach viel größer ist!
              <br />
              <br />
              Hat der Browser noch eine zweite/eigene Menüleiste im unteren Bereich, kann das Navigieren kompliziert werden. Hier hilft nur ein sauberes Design mit großzügigen Abständen, um einen versehentlichen falschen Klick vermeidbar zu machen. (siehe Abbildung 4).
            </p>
          </span>
        }
      />
      <Image
        image={Abstaende}
        title="Abbildung 4: doppelte Navigationsleiste mit wenig und viel Abstand"
      />
      <TextBox
        title=""
        text={
          <span>
            <p>
              Dasselbe Problem könnte beim Scrollen entstehen, da sich hier der Daumen ebenfalls im unteren Bereich bewegt. Beide Probleme bekommt man in den Griff, wenn eingestellt wird, dass die Navigationsleiste ausblendet, solange der Benutzer:innen die Seite herunterscrollt. Die Leiste wird nur angezeigt, wenn Benutzer:innen zurück/nach oben scrollen. Dies ist eine gängige Technik, die bereits in mobilen Apps und Browsern verwendet wird.
            </p>
          </span>
        }
      />
      <Image
        image={Verschwinden}
        title="Abbildung 5: Lösung durch Ausblenden der Navigation beim Nach-Unten-Scrollen"
      />
      <TextBox
        title=""
        text={
          <span>
            <h2>holzweg Conclusio</h2>
            <p>
              Ziel einer guten User Experience auf Smartphones ist es, die
              Navigation so schnell und reibungslos wie möglich zu gestalten. Da der Daumen primär zur Navigation auf Smartphones eingesetzt wird, sollte dieser die Navigation diktieren dürfen. <br/><br/>Wir sprechen uns also für eine Navigation im unteren Bereich des Handy-Bildschirms aus, sofern diese mit genügend Abstand versehen ist und beim Nach-Unten-Scrollen ausblendet!
            </p>
          </span>
        }
      />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default MobileNavigationGanzUnten
